<template>
  <v-app id="login ">
    <v-main
      class="login-panel"
      :style="{ backgroundImage: `url(${backgroundUrl})` }"
    >
      <v-container fluid>
        <loading :active="isLoading" :loader="loader" />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import backgroundUrl from "../../assets/images/didpanel.jpg";
import axios from "axios";
import deviceAPI from "../../services/deviceAPI";

export default {
  data() {
    return {
      isLoading: true,
      loader: "bars",
      backgroundUrl: "",
      userData: {},
      authToken: "",
      accountId: "",
    };
  },

  components: {
    Loading,
  },

  beforeMount() {
    if (this.$route.query && this.$route.query.auth_token) {
      this.userData = JSON.parse(this.$route.query.userDetails);
      localStorage.setItem("auth", this.$route.query.auth);
      localStorage.setItem("authToken", this.$route.query.auth_token);
      this.accountId = this.$route.query.accountId;
      this.readDataFromAPI();
      this.doLogin();
    } else {
      localStorage.clear();
      window.location.href = process.env.VUE_APP_REDIRECT_URL;
    }
    this.backgroundUrl = backgroundUrl;
    this.doLogin();
  },

  methods: {
    async readDataFromAPI() {
      this.loading = true;
      await axios.get("https://ipapi.co/json/").then((response) => {
        this.ipAdddress = response.data.ip;
        this.countryCode = response.data.country;
        if (this.ipAdddress == null) {
          localStorage.setItem("ipAddress", "192.167.1.4");
          localStorage.setItem("countryCode", "IN");
        } else {
          localStorage.setItem("ipAddress", this.ipAdddress);
          localStorage.setItem("countryCode", this.countryCode);
        }
        this.ipAdddress = localStorage.getItem("ipAddress");
        this.countryCode = localStorage.getItem("countryCode");
        this.loading = false;
      });
    },

    async doLogin() {
      this.isLoading = true;
      let data = {
        accountId: this.accountId,
      };
      try {
        let response = await deviceAPI.getAccountDetail(data);
        this.userData.partyId = response.accountdetails.name;
        this.userData.realm = response.accountdetails.realm;
        localStorage.setItem("userDetail", JSON.stringify(this.userData));
        this.$router.push("/didnPbx");
        this.isLoading = false;
      } catch (error) {
        console.log("====error===", error);
      }
    },
  },
};
</script>

<style scoped>
</style>
